<template>
  <div class="fields-links">
    <h1 v-show="isMobile">only for dekstop version</h1>
    <div v-show="!isMobile" class="block-links first-block-links">
      <div class="links">
        <h2>
          <v-icon small class="icon-apple" color="black"> fab fa-windows</v-icon
          >Windows
        </h2>
        <div>
          Once the archive is downloaded, unzip it<br />
          and drag the Rendezvu file to your desktop <br />or other preferred
          location
        </div>
        <div>
          Launch Rendezvu and enter your<br />
          username and password
        </div>
        <div>Choose server</div>
        <div>
          Go through the tutorial, customize<br />
          your character, and welcome to the Vu!
        </div>
      </div>
      <app-center-download
        class="btn-links"
        :apiToken="Windows.apiToken"
        :appSecret="Windows.appSecret"
        :guid="Windows.guid"
      />
    </div>
    <div v-show="!isMobile" class="block-links">
      <div class="links">
        <h2>
          <v-icon class="icon-apple" small color="black"> fab fa-apple</v-icon
          >MacOS
        </h2>
        <!-- <div>
        <a v-bind:href="MacOsUrl" target="_blank">Download application</a>
      </div> -->
        <div>
          Once the file is downloaded, open it<br />
          and drag the Rendezvu file to the<br />
          Applications folder.
        </div>
        <div>
          Launch Rendezvu and enter your <br />
          username and password
        </div>
        <div>Choose server</div>
        <div>
          Go through the tutorial, customize your <br />
          character, and welcome to the Vu!
        </div>
      </div>
      <app-center-download
        class="btn-links"
        :apiToken="MacOS.apiToken"
        :appSecret="MacOS.appSecret"
        :guid="MacOS.guid"
      />
    </div>
  </div>
</template>

<script>
import AppCenterDownload from "./AppCenterDownload.vue";
import { Config } from "@/config/config";

export default {
  data() {
    return {
      Windows: Config.appCenterWin,
      MacOS: Config.appCenterMac,
      isMobile: false,
    };
  },
  mounted() {
    const devices = new RegExp(
      "Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini",
      "i"
    );

    if (devices.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  components: { AppCenterDownload },
};
</script>

<style lang="scss">
.fields-links {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(233, 247, 249, 0.5);
  width: 100vw;
  height: calc(100vh - 12vh);
  // padding: 10px 0px;
}
.block-links {
  background-color: white;
  width: 35vw;
  border: 1px solid #e9eeef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px 0px 20px 40px;
}
.links {
  div {
    border-left: 2px solid rgba(173, 199, 204, 0.5);
    font-size: 14px;
    line-height: 145%;
    margin-bottom: 15px;
    padding-left: 30px;
  }
  h2 {
    margin-bottom: 30px;
    .fab {
      margin-right: 20px;
    }
  }
}
.first-block-links {
  margin-right: 4vw;
}
.download-path {
  margin-top: 3vh;
  font-weight: 600;
  line-height: 100%;
}
.btn-links {
  width: 290px;
  color: white;
}
@media screen and (max-device-width: 1100px) and (orientation: landscape) {
  .links {
    div {
      font-size: 12px;
    }
    h2 {
      margin-bottom: 24px;
      .fab {
        margin-right: 20px;
      }
    }
  }
  .btn-links {
    width: 250px;
  }
}
@media screen and (max-device-width: 950px) and (orientation: landscape) {
  .block-links {
    background-color: white;
    width: 35vw;
    border: 1px solid #e9eeef;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px 0px 20px 20px;
  }
  .links {
    div {
      font-size: 11px;
      @media screen and (max-device-width: 900px) {
        font-size: 10px;
      }
      @media screen and (max-device-width: 800px) {
        font-size: 9px;
      }
    }
    h2 {
      margin-bottom: 18px;
      @media screen and (max-device-width: 900px) {
        font-size: 16px;
      }
      .fab {
        margin-right: 20px;
      }
    }
  }
  .btn-links {
    width: 220px;
    @media screen and (max-device-width: 900px) {
      width: 200px;
    }
  }
}
@media screen and (min-width: 3800px) {
  .block-links {
    background-color: white;
    width: 35vw;
    border: 1px solid #e9eeef;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 70px 0px 100px 100px;
  }
  .links {
    div {
      border-left: 2px solid rgba(173, 199, 204, 0.5);
      font-size: 40px;
      line-height: 165%;
      margin-bottom: 15px;
      padding-left: 50px;
    }
    h2 {
      margin-bottom: 45px;
      font-size: 64px;
      .fab {
        margin-right: 50px;
      }
    }
  }
  .first-block-links {
    margin-right: 4vw;
  }
  .download-path {
    margin-top: 3vh;
    font-weight: 600;
    line-height: 100%;
  }
  .btn-links {
    width: 590px;
    color: white;
  }
}
</style>
