var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fields-links"},[_c('h1',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]},[_vm._v("only for dekstop version")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"block-links first-block-links"},[_c('div',{staticClass:"links"},[_c('h2',[_c('v-icon',{staticClass:"icon-apple",attrs:{"small":"","color":"black"}},[_vm._v(" fab fa-windows")]),_vm._v("Windows ")],1),_vm._m(0),_vm._m(1),_c('div',[_vm._v("Choose server")]),_vm._m(2)]),_c('app-center-download',{staticClass:"btn-links",attrs:{"apiToken":_vm.Windows.apiToken,"appSecret":_vm.Windows.appSecret,"guid":_vm.Windows.guid}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"block-links"},[_c('div',{staticClass:"links"},[_c('h2',[_c('v-icon',{staticClass:"icon-apple",attrs:{"small":"","color":"black"}},[_vm._v(" fab fa-apple")]),_vm._v("MacOS ")],1),_vm._m(3),_vm._m(4),_c('div',[_vm._v("Choose server")]),_vm._m(5)]),_c('app-center-download',{staticClass:"btn-links",attrs:{"apiToken":_vm.MacOS.apiToken,"appSecret":_vm.MacOS.appSecret,"guid":_vm.MacOS.guid}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Once the archive is downloaded, unzip it"),_c('br'),_vm._v(" and drag the Rendezvu file to your desktop "),_c('br'),_vm._v("or other preferred location ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Launch Rendezvu and enter your"),_c('br'),_vm._v(" username and password ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Go through the tutorial, customize"),_c('br'),_vm._v(" your character, and welcome to the Vu! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Once the file is downloaded, open it"),_c('br'),_vm._v(" and drag the Rendezvu file to the"),_c('br'),_vm._v(" Applications folder. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Launch Rendezvu and enter your "),_c('br'),_vm._v(" username and password ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Go through the tutorial, customize your "),_c('br'),_vm._v(" character, and welcome to the Vu! ")])
}]

export { render, staticRenderFns }