<template>
  <div
    elevation="2"
    :loading="isLoading"
    :disabled="isLoading"
    class="download-btn"
  >
    <v-banner icon="fa-arrow-circle-down" elevation="2" rounded>
      <a :href="downloadUrl">Download {{ version }}</a>
    </v-banner>
  </div>
</template>

<script>
export default {
  props: {
    appSecret: { type: String, required: true },
    guid: { type: String, required: true },
    apiToken: { type: String, required: true },
  },
  mounted() {
    const url = `https://api.appcenter.ms/v0.1/public/sdk/apps/${this.appSecret}/distribution_groups/${this.guid}/releases/latest`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "X-API-Token": this.apiToken,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.isLoading = false;
        this.downloadUrl = data.download_url;
        let versionStr =
          data.short_version.length > 0
            ? `${data.short_version} (${data.version})`
            : data.version;
        this.version = `- v${versionStr}`;
        this.$forceUpdate();
      });
  },
  data() {
    return {
      isLoading: true,
      version: "",
      downloadUrl: "",
    };
  },
};
</script>
 <style>
@media screen and (min-width: 3800px) {
  .download-btn {
    font-size: 45px;
  }
  .v-application--is-ltr .v-banner--has-icon .v-banner__wrapper {
    padding-left: 16px;
    height: 150px;
  }
}
@media screen and (max-device-width: 1100px) and (orientation: landscape) {
  .download-btn {
    font-size: 12px;
  }
  .v-application--is-ltr .v-banner--has-icon .v-banner__wrapper {
    padding-left: 16px;
  }
  .v-banner--is-mobile.v-banner--has-icon .v-banner__wrapper {
    padding-top: 10px;
  }

  .v-banner--is-mobile .v-banner__wrapper {
    padding-top: 0px;
  }

  .v-application--is-ltr .v-banner__wrapper {
    padding: 10px 0px 10px 24px;
  }
}

@media screen and (max-device-width: 900px) {
  .download-btn {
    font-size: 11px;
  }
  .v-banner--is-mobile.v-banner--has-icon .v-banner__wrapper {
    padding-top: 7px;
  }

  .v-banner--is-mobile .v-banner__wrapper {
    flex-wrap: wrap;
    padding-top: 0px;
  }

 .v-banner--is-mobile.v-banner--has-icon .v-banner__wrapper {
    padding-top: 12px !important;
}
  .v-application--is-ltr .v-banner__wrapper {
    padding: 7px 0px 7px 14px !important;
  }
}
</style>
