export const Config = {
    appCenterWin: {
        appSecret: "4c4fb65a-1008-438a-a7a7-dca338f2a9cb",
        guid: "9fe2d59a-5432-44ca-90d9-a11b2411c855",
        apiToken: "8ae7857d514b30969941be402b24a682f2fc12d8"
    },
    appCenterMac: {
        appSecret: "c991e59f-56cd-45c5-92bc-8833c0c5cbae",
        guid: "d32b781e-9d12-4877-9039-d237d5c9f31f",
        apiToken: "daec37fff6f35b81d6d911d3db35b7f34e3bc5a8"
    }
}
